var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", flat: "" } },
    [
      _vm.headerIsOn
        ? _c(
            "v-row",
            { staticClass: "pt-3 mb-4" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { dark: "", flat: "", color: "#424242" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text-subtitle-1 d-flex justify-center",
                        },
                        [_vm._v(_vm._s(_vm.headerTitle) + "を選ぶ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "carousel",
        {
          attrs: {
            "per-page": ["md", "lg", "xl"].includes(
              _vm.$vuetify.breakpoint.name
            )
              ? _vm.numItemsForDesktop
              : _vm.numItemsForMobile,
            "pagination-color": "#E53935",
            "pagination-active-color": "#3949AB",
            touchDrag: true,
          },
        },
        _vm._l(_vm.inputItems, function (item) {
          return _c(
            "slide",
            { key: item.name, attrs: { align: "center" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex align-center justify-center",
                  attrs: {
                    color: item.backgroundColor,
                    "max-width": "100",
                    "min-height": "100",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.emitItem(item)
                    },
                  },
                },
                [
                  _c("v-img", {
                    staticClass: "imgOpacity",
                    attrs: { alt: "item.name", contain: "", src: item.src },
                  }),
                ],
                1
              ),
              _c("span", { staticClass: "text-h8 d-flex justify-center" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }